import * as React from "react";
import { Container, Typography } from "@mui/material";
import "../style/style.scss";
import { Link } from "gatsby-theme-material-ui";

// markup
const WorkWith = () => {
  return (
    <>
      <div className="backButton">
        <Link className="mdxBtn" to="/alternative">
          Back
        </Link>
      </div>
      <Container maxWidth="md" className="content-alt center-wrap">
        <Typography className="modal-title" variant="h3">
          CONTACT
        </Typography>
        <Typography className="modal-text" variant="body1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at ex sit
          amet mi accumsan molestie a in tellus. Morbi rhoncus augue dictum
          tincidunt interdum. Sed nec dolor lacus. Suspendisse feugiat volutpat
          mattis.
        </Typography>
        <Typography className="modal-text" variant="body1">
          MDAXX Specialises in:
        </Typography>
        <ul>
          <li>One item</li>
          <li>Two item</li>
          <li>Three item</li>
          <li>Four item</li>
        </ul>
        <Typography className="modal-text" variant="body1">
          Contact us by <a href="mailto:mike@mdaxx.com">email</a>
        </Typography>
      </Container>
    </>
  );
};

export default WorkWith;
